import React from 'react';
import {Image} from "@aws-amplify/ui-react";
import {
    DropdownMenu,
    DropdownMenuContent, DropdownMenuItem,
    DropdownMenuTrigger
} from "../../../shared/components/ui/dropdown-menu/DropdownMenu";
import {Button} from "../../../shared/components/ui/button/Button";
import {CircleUser} from "lucide-react";
import { FormattedMessage } from 'react-intl';

const Header = ({signOut}) => {
    return (
        <header className="flex h-14 items-center gap-4 border-b bg-muted/40 px-4 lg:h-[60px] lg:px-6 bg-primary">
            <div className="w-full flex-1">
                <div className="relative">
                    <a href="/src/components/public" className="flex items-center gap-4 font-semibold">
                        <Image
                            alt="AdQuiver Logo"
                            src="/Logo-imagotipo-AdQuiver-8.png"
                            height="25px"
                            width="auto"
                        />
                        <span className="text-white">AdQuiver secure file upload</span>
                    </a>
                </div>
            </div>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button variant="ghost" size="icon" className="rounded-full text-white">
                        <CircleUser className="h-6 w-6"/>
                        <span className="sr-only">Toggle user menu</span>
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                    <DropdownMenuItem onClick={signOut}>
                        <FormattedMessage 
                            id="app.logout"
                            defaultMessage="Logout"/>
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </header>
    );
};

export default Header;