/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:b61d5d70-6b7b-417c-b569-8ad56047631d",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_4H5pCLTxe",
    "aws_user_pools_web_client_id": "tfslviboi9gm70a0i90nied3u",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "sensitive-landing-zone-deployment-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://dymt9h89c74hc.cloudfront.net",
    "aws_user_files_s3_bucket": "sensitive-landing-zone-bucketc4758-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
