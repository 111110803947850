import React from 'react';
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "../../../../shared/components/ui/table/Table";
import { Badge } from "../../../../shared/components/ui/badge/Badge"
import UploadFilesTableEntry from "./UploadFilesTableEntry";
import {ScrollArea} from "../../../../shared/components/ui/scroll-area/ScrollArea";

const UploadFIlesTable = ({files}) => {

    return (
        <div className="flex flex-1 items-center justify-center">
            <Table className="shadow-lg rounded-lg">
                <TableBody>
                    <ScrollArea className="h-[350px]" type="always">
                        {
                            files.map(file => (
                                <UploadFilesTableEntry file={file} key={file.id}/>
                            ))
                        }
                    </ScrollArea>
                </TableBody>
            </Table>
        </div>
    );
};

export default UploadFIlesTable;