import React from 'react';
import AuthenticationSectionHeader from "../header/AuthenticationSectionHeader";
import {Lock} from "lucide-react";
import { useIntl } from 'react-intl';

const SignInHeader = () => {
    const intl = useIntl()

    const title = intl.formatMessage({id: 'app.login', defaultMessage: 'Login'})
    const description = intl.formatMessage({id: 'app.loginDescription', defaultMessage: 'Enter your email below to login to your account'})

    return (
        <AuthenticationSectionHeader
            title={title}
            descripcion={description}
            icon={<Lock size={24}/>}
        />
    );
};

export default SignInHeader;