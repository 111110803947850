import React from 'react';
import { FormattedMessage } from 'react-intl';

const UploadFilesTitle = () => {
    return (
        <div className="flex flex-col gap-2">
            <h1 className="text-lg font-semibold md:text-2xl">
                <FormattedMessage
                    id="app.uploadFiles"
                    defaultMessage="Upload files"/>
            </h1>
            <div className="flex flex-col gap-1">
                <p className="text-sm text-muted-foreground pb-4">
                    <FormattedMessage
                        id="app.welcomeText"
                        defaultMessage="Welcome to the AdQuiver Secure File Upload Portal. This platform is designed for the secure transmission and storage of your sensitive files. Upon upload, your data is securely stored, accessible only to our data engineering team. This team is responsible for reviewing and processing the data, ensuring any necessary anonymization or removal of sensitive information before files are analysed."/>
                </p>
                <p className="text-sm text-muted-foreground pb-4">
                    <strong>
                        <FormattedMessage
                            id="app.welcomeGuideTitle"
                            defaultMessage="How to Upload Your Files"/>
                    </strong>
                    <br/>
                    <span>
                        <FormattedMessage
                            id="app.welcomeGuideStep1"
                            defaultMessage="- Drag and Drop: Simply drag and drop your files into the area below."/>
                    </span>
                    <br/>
                    <span>
                        <FormattedMessage
                            id="app.welcomeGuideStep2"
                            defaultMessage="- Manual Selection: Click on the area below to open your file explorer and select the files you wish to upload."/>
                    </span>
                    <br/>
                    <span>
                        <FormattedMessage
                            id="app.welcomeGuideStep3"
                            defaultMessage="- Once your files are selected, click the Upload files button to securely transmit your data to our storage system."/>
                    </span>
                </p>
            </div>
        </div>
    );
};

export default UploadFilesTitle;