import React from 'react';
import {CardDescription, CardTitle} from "../../../../shared/components/ui/card/Card";
import {KeyRound, Mail} from "lucide-react";
import AuthenticationSectionHeader from "../header/AuthenticationSectionHeader";
import { useIntl } from 'react-intl';

const ConfirmChangePasswordHeader = () => {
    const intl = useIntl();

    const title = intl.formatMessage({id: 'app.changePassword', defaultMessage: 'Change your password'})
    const description = intl.formatMessage({id: 'app.changePasswordDescription', defaultMessage: 'Please, check your inbox and enter the code to change your password. New passwords must be at least 8 characters long.'})

    return (
        <AuthenticationSectionHeader
            title={title}
            description={description}
            icon={<Mail size={32}/>}
        />
    );
};

export default ConfirmChangePasswordHeader;