import React from 'react';
import {Image} from "@aws-amplify/ui-react";
import {CardTitle} from "../../../../shared/components/ui/card/Card";

const AuthenticationHeader = () => {
    return (
        <div className="flex justify-center gap-8 pb-10">
            <Image
                alt="AdQuiver Logo"
                src="/ISO-color-positivo.png"
                height="80px"
                width="auto"
            />
            <CardTitle className="text-4xl font-light antialiased text-primary opacity-70">AdQuiver secure file upload</CardTitle>
        </div>
    );
};

export default AuthenticationHeader;