import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
    return twMerge(clsx(inputs))
}

export const formatBytes = (a, b = 2, k = 1024) => {
    let d = Math.floor(Math.log(a) / Math.log(k));
    return 0 === a ? "0 Bytes" : parseFloat((a / Math.pow(k, d)).toFixed(Math.max(0, b))) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d];
}

export const getLocaleFromBrowser = () => {
    const browserLanguage = navigator.language || navigator.userLanguage;
    
    switch (browserLanguage) {
        case 'en':
        case 'en-US':
        case 'en-GB':
            return 'en';
        case 'es':
        case 'es-ES':
        case 'es-MX':
        case 'es-AR':
            return 'es';
        default:
            return 'en'; // valor por defecto si no es inglés o español
    }
}