import {
    Bell,
    CircleUser,
    Home, Info,
    LineChart,
    Menu,
    Package,
    Package2,
    Search,
    ShoppingCart,
    Upload,
    Users,
} from "lucide-react"
import {Button} from "../../../shared/components/ui/button/Button";
import {
    DropdownMenu,
    DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator,
    DropdownMenuTrigger
} from "../../../shared/components/ui/dropdown-menu/DropdownMenu";
import UploadFilesDragArea from "./UploadFilesDragArea";
import {useId, useState} from "react";
import {Auth, Storage} from "aws-amplify";
import { v4 as uuid } from 'uuid'
import {formatBytes} from "../../../shared/lib/utils";
import UploadFIlesTable from "./table/UploadFIlesTable";
import {toast, Toaster} from "sonner";
import {Image} from "@aws-amplify/ui-react";
import UploadFilesTitle from "./UploadFilesTitle";
import Header from "../header/Header";
import { FormattedMessage, useIntl } from "react-intl";


const UploadFilesContainer = ({signOut, user}) => {
    const intl = useIntl();
    const [files, setFiles] = useState([]);
    const [filesUploaded, setFilesUploaded] = useState([]);

    const handleFileChange = (filesUploaded)=> {
        setFiles(filesUploaded);
    }

    const getDateFormatted = () => {
        const date = new Date();
        
        const year = date.getFullYear();
        let month = (date.getMonth() + 1).toString();
        let day = date.getDate().toString();
      
        if (month.length < 2) {
          month = '0' + month;
        }
        if (day.length < 2) {
          day = '0' + day;
        }
      
        const formattedDate = year + month + day;
        return formattedDate;
    }

    const handleUploadFiles = () => {
        if(files.length <= 0) {
            const noFilesMsg = intl.formatMessage({
                id: "app.noFilesWarning",
                defaultMessage: 'Please, drag and drop files to upload them'
            });

            toast.warning(noFilesMsg);

        } else {
            const uploadCompleted = [];
            const clientFolder = user.attributes['custom:client'] ?? 'unknown-client';
            const dateFormatted = getDateFormatted();

            files.forEach((file) => {

                const fileId = uuid();
                const uploadPath = `${clientFolder}/${dateFormatted}/${file.path}`;
                setFilesUploaded(prevFiles =>
                    [{
                        id: fileId,
                        percentage: 0,
                        filename: file.name,
                        filetype: file.type,
                        filesize: formatBytes(file.size),
                        status: 'in-progress'
                    }, ...prevFiles]
                )

                uploadCompleted.push(Storage.put(uploadPath, file, {
                        progressCallback: (progress) => updateFileUploadProgress(progress, fileId),
                        metadata: {
                            email: user.attributes.email ?? 'unknown-email',
                            client: clientFolder
                        }

                    }).then((result) => {
                        const uploadCompletedMsg = intl.formatMessage({
                            id: "app.uploadCompleted",
                            defaultMessage: 'Completed the upload of {filename}',
                        },{
                            filename: file.name,
                        });

                        toast.success(uploadCompletedMsg)

                    }).catch((error) => {
                        const uploadFailedMsg = intl.formatMessage({
                            id: "app.uploadFailed",
                            defaultMessage: 'Failed uploading file {error}',
                        },{
                            error: error,
                        });

                        toast.error(`Failed uploading file ${uploadFailedMsg}`)
                        console.log(`Failed uploading file ${error}`)
                    })
                )
            })

            Promise.all(uploadCompleted).then(() => setFiles([]));
        }
    }  

    const updateFileUploadProgress = (progress, fileId) => {
        const percentage = Math.round((progress.loaded / progress.total) * 100);

        setFilesUploaded(prevFiles =>
            prevFiles.map(file =>
                file.id === fileId
                    ? {
                        ...file,
                        percentage: percentage,
                        status: percentage === 100 ? 'success' : 'in-progress'
                    }
                    : file
            )
        );
    }

    return (
        <div className="flex flex-col">
            <Header signOut={signOut}/>
            <div className="flex flex-col justify-center items-center pt-4">
                <main className="flex flex-1 flex-col gap-8 p-4 w-screen md:w-[950px]">
                    <div className="flex flex-col gap-4">
                        <UploadFilesTitle />
                        <div className="flex flex-1 items-center justify-center rounded-lg border border-dashed shadow-sm">
                            <UploadFilesDragArea
                                files={files}
                                onFileChange={handleFileChange}/>
                        </div>
                        <Button className="w-fit" onClick={handleUploadFiles}>
                            <FormattedMessage
                                id="app.uploadFiles"
                                defaultMessage="Upload files"/>
                        </Button>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className="flex flex-1 items-center justify-center">
                            <UploadFIlesTable files={filesUploaded}/>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default UploadFilesContainer;
