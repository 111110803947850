import React, { useEffect } from 'react';
import {I18n} from 'aws-amplify';
import {Authenticator} from '@aws-amplify/ui-react';
import {AutenticatorComponents} from "./Autenticator";
import { translations } from '@aws-amplify/ui-react';
import { useIntl } from 'react-intl';


function AuthenticatorWrapper({ children, locale }) {
    const intl = useIntl();

    useEffect(() => {
        I18n.putVocabularies(translations);
        I18n.setLanguage(locale);
        I18n.putVocabulariesForLanguage(locale, {
            'Back to Sign In': intl.formatMessage({id: 'app.backToLoginFromForgotPassword', defaultMessage: 'Forgot password?'}),
            'Change Password': intl.formatMessage({id: 'app.createPassword', defaultMessage: 'Create a new password'})
        });
    }, [locale]);

    const autenticatorFormFields = {
        signIn: {
            username: {
                placeholder: intl.formatMessage({id: 'app.enterEmail', defaultMessage: 'Enter your email'}),
            },
            password: {
                placeholder: intl.formatMessage({id: 'app.enterPassword', defaultMessage: 'Enter your password'}),
            },
        },
        signUp: {
            password: {
                label: intl.formatMessage({id: 'app.password', defaultMessage: 'Password:'}),
                placeholder: intl.formatMessage({id: 'app.enterPassword', defaultMessage: 'Enter your password'}),
                isRequired: false,
                order: 2,
            },
            confirm_password: {
                label: intl.formatMessage({id: 'app.confirmPassword', defaultMessage: 'Confirm Password:'}),
                placeholder: intl.formatMessage({id: 'app.enterPassword', defaultMessage: 'Enter your password'}),
                order: 1,
            },
        },
        forceNewPassword: {
            password: {
                placeholder: intl.formatMessage({id: 'app.enterPassword', defaultMessage: 'Enter your password'}),
            },
            confirm_password: {
                label: intl.formatMessage({id: 'app.confirmPassword', defaultMessage: 'Confirm Password:'}),
                placeholder: intl.formatMessage({id: 'app.enterPassword', defaultMessage: 'Enter your password'}),
            }
        },
        resetPassword: {
            username: {
                label: 'Email',
                placeholder: intl.formatMessage({id: 'app.enterEmail', defaultMessage: 'Enter your email'})
            }
        },
        confirmResetPassword: {
            confirmation_code: {
                placeholder: intl.formatMessage({id: 'app.enterConfirmationCode', defaultMessage: 'Enter your confirmation code'}),
                label: 'Code',
                isRequired: false,
            },
            confirm_password: {
                placeholder: intl.formatMessage({id: 'app.enterPassword', defaultMessage: 'Enter your password'}),
            },
        }
    };

    return <Authenticator
        className="flex justify-center items-center h-full"
        hideSignUp
        formFields={autenticatorFormFields}
        components={AutenticatorComponents}>
        {children}
    </Authenticator>
}

export default AuthenticatorWrapper;