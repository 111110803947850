import React from 'react';
import {CardDescription, CardTitle} from "../../../../shared/components/ui/card/Card";
import {KeyRound} from "lucide-react";
import AuthenticationSectionHeader from "../header/AuthenticationSectionHeader";
import { useIntl } from 'react-intl';

const ChangePasswordHeader = () => {

    const intl = useIntl();

    const title = intl.formatMessage({id: 'app.forgotPassword', defaultMessage: 'Forgot password?'})
    const description = intl.formatMessage({id: 'app.forgotPasswordDescription', defaultMessage: 'You will receive an email with a code to reset your password.'})

    return (
        <AuthenticationSectionHeader
            title={title}
            description={description}
            icon={<KeyRound size={32}/>}
        />
    );
};

export default ChangePasswordHeader;