import React from 'react';
import {CardDescription, CardTitle} from "../../../../shared/components/ui/card/Card";

const AuthenticationSectionHeader = ({ title, description, icon}) => {
    return (
        <div className="flex justify-between">
            <div>
                <CardTitle className="text-2xl">{title}</CardTitle>
                <CardDescription>
                    {description}
                </CardDescription>
            </div>
            {icon}
        </div>
    );
};

export default AuthenticationSectionHeader;