import React from 'react';
import '@aws-amplify/ui-react/styles.css';
import './App.css';
import awsconfig from './aws-exports';
import {Amplify} from 'aws-amplify';
import UploadFilesContainer from "./components/private/upload-files/UploadFilesContainer";
import {Toaster} from "sonner";
import { IntlProvider } from "react-intl";
import messages_es from './shared/lan/es.json'
import messages_en from './shared/lan/en.json'
import AuthenticatorWrapper from './components/public/auth/AuthenticatorWrapper';
import { getLocaleFromBrowser } from './shared/lib/utils';

// Initialize Amplify configuration
Amplify.configure(awsconfig);

// Set translation based on locale
const messages = {
    en: messages_en,
    es: messages_es
};

function App() {
    const locale = getLocaleFromBrowser();

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <AuthenticatorWrapper awsconfig={awsconfig} locale={locale}>
                {({signOut, user}) => (
                    <>
                        <Toaster richColors/>
                        <UploadFilesContainer signOut={signOut} user={user}/>
                    </>
                )}
            </AuthenticatorWrapper>
        </IntlProvider>
    );
}

export default App;
