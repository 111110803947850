import {FileUploader, FileUploaderContent, FileUploaderItem, FileInput} from "../../../shared/components/ui/file-upload/FileUpload";
import FileSvgDraw from "../../../shared/components/ui/file-upload/FileSvgDraw";
import {Paperclip} from "lucide-react";
import { useIntl } from "react-intl";

const UploadFilesDragArea = ({files, onFileChange}) => {
    const intl = useIntl()

    const dropZoneConfig = {
        maxFiles: 5,
        maxSize: 1024 * 1024 * 5000,
        multiple: true,
    };

    const translations = {
        clickUpload: intl.formatMessage({id: "app.clickUpload", defaultMessage: "Click to upload"}),
        dragDrop: intl.formatMessage({id: "app.dragDrop", defaultMessage: "or drag and drop"}),
        maxFileSize: intl.formatMessage({id: "app.maxFileSize", defaultMessage: "Max file size: 5 GB"})
    }

    return (
        <FileUploader
            value={files}
            onValueChange={onFileChange}
            dropzoneOptions={dropZoneConfig}
            className="relative bg-background rounded-lg p-2"
        >
            <FileInput className="outline-dashed outline-1 outline-white">
                <div className="flex items-center justify-center flex-col pt-3 pb-4 w-full ">
                    <FileSvgDraw translations={translations}/>
                </div>
            </FileInput>
            <FileUploaderContent>
                {files &&
                    files.length > 0 &&
                    files.map((file, i) => (
                        <FileUploaderItem key={i} index={i}>
                            <Paperclip className="h-4 w-4 stroke-current" />
                            <span>{file.name}</span>
                        </FileUploaderItem>
                    ))}
            </FileUploaderContent>
        </FileUploader>
    );
};

export default UploadFilesDragArea;