import React from 'react';
import {useAuthenticator} from "@aws-amplify/ui-react";
import {Button} from "../../../../shared/components/ui/button/Button";
import {ArrowLeftCircleIcon} from "lucide-react";
import {FormattedMessage} from 'react-intl'

const ConfirmChangePasswordFooter = () => {
    const { toSignIn } = useAuthenticator();

    return (
        <Button
            variant="destructive"
            onClick={toSignIn}
            className="text-sm flex gap-2 self-center">
            <ArrowLeftCircleIcon />
            <p>
            <FormattedMessage
                id="app.returnToSignIn"
                defaultMessage="Cancel and return to sign in"/>
            </p>
        </Button>
    );
};

export default ConfirmChangePasswordFooter;