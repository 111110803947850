import React from 'react';
import {useAuthenticator} from "@aws-amplify/ui-react";
import {Button} from "../../../../shared/components/ui/button/Button";
import {FormattedMessage} from 'react-intl'

const SignInFooter = () => {
    const { toResetPassword } = useAuthenticator();

    return (
        <div className="flex w-full items-center justify-center">
            <Button
                variant="ghost"
                onClick={toResetPassword}
                className="text-muted-foreground text-sm hover:bg-transparent self-center">
                <FormattedMessage
                    id="app.forgotPassword"
                    defaultMessage="Forgot password?"/>
            </Button>
        </div>
    );
};

export default SignInFooter;