import React from 'react';
import {TableCell, TableRow} from "../../../../shared/components/ui/table/Table";
import {Badge} from "../../../../shared/components/ui/badge/Badge";
import {Check, File, X} from "lucide-react";
import {Progress} from "../../../../shared/components/ui/progress/Progress";
import {cn} from "../../../../shared/lib/utils";

const UploadFilesTableEntry = ({file}) => {

    const fileStatus = {
        'in-progress': {
            color: 'bg-primary',
            text: `${file.percentage}%`,
            icon: <File size={24} className="text-muted-foreground"/>
        },
        'error': {
            color: 'bg-red-500',
            text: 'Error',
            icon: <X size={24} className="text-red-500"/>
        },
        'success': {
            color: 'bg-green-500',
            text: '100%',
            icon: <Check size={24} className="text-green-500"/>
        },
    }

    return (
        <TableRow className='px-0 w-full justify-between items-center h-16'>
            <TableCell className="font-medium">
                <div className="flex gap-4 items-center">
                    {fileStatus[file.status].icon}
                    <div className="flex flex-col justify-start gap-1">
                        <span className="font-medium">{file.filename}</span>
                        <span className="text-xs text-muted-foreground">{file.filesize} - {file.filetype}</span>
                    </div>
                </div>
            </TableCell>
            <TableCell className="w-80">
                <div className="flex gap-4 items-center">
                    <Progress value={file.percentage} barColor={fileStatus[file.status].color}/>
                    <Badge className={cn("px-2 py-1 text-xs w-[60px] flex justify-center", fileStatus[file.status].color)}>
                        {fileStatus[file.status].text}
                    </Badge>
                </div>
            </TableCell>
        </TableRow>
    );
};

export default UploadFilesTableEntry;