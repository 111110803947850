import AuthenticationHeader from "./header/AuthenticationHeader";
import SignInHeader from "./sign-in/SignInHeader";
import SignInFooter from "./sign-in/SignInFooter";
import ChangePasswordHeader from "./change-password/ChangePasswordHeader";
import ConfirmChangePasswordHeader from "./confirm-change-password/ConfirmChangePasswordHeader";
import ConfirmChangePasswordFooter from "./confirm-change-password/ConfirmChangePasswordFooter";

export const AutenticatorComponents = {
    Header() {
        return <AuthenticationHeader />;
    },

    SignIn: {
        Header() {
            return <SignInHeader />;
        },
        Footer() {
            return <SignInFooter />
        },
    },

    ResetPassword: {
        Header() {
            return <ChangePasswordHeader />;
        }
    },

    ConfirmResetPassword: {
        Header() {
            return <ConfirmChangePasswordHeader />;
        },

        Footer() {
            return <ConfirmChangePasswordFooter />
        }
    },
};